/* eslint-disable react/no-array-index-key */
import {
  Animation,
  EventTracker,
  Stack,
  ValueScale,
} from '@devexpress/dx-react-chart'
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Legend,
  Title,
  Tooltip,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip as ToolTips,
  Typography,
} from '@mui/material'
import Rating from '@mui/material/Rating'
import Aos from 'aos'
import {
  AllPlatformIcon,
  ArrowRight,
  Close,
  Comments,
  Edit,
  FastabLogoIcon,
  GoogleLogo,
  IcNext,
  IcPrev,
  // StarFilled,
  StarIcon,
  // StarNormal,
  TimeIcon,
  View,
} from 'assets/images'
import FileUpload from 'components/FileUpload/Index'
import Header from 'components/Header/Index'
import Pagination from 'components/Pagination/Index'
import Select from 'components/Select/Index'
import Sidebar from 'components/Sidebar/Index'
import CryptoJS from 'crypto-js'
import {
  establishmentList,
  selectedEstablishment,
  setCurrentEstablishment,
  setEstablishments,
} from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { gapi } from 'gapi-script'
import { IDatagrid } from 'interfaces/datagrid'
import React, { useEffect, useState } from 'react'
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'
import { Helmet } from 'react-helmet'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import authService from 'services/auth.service'
import blobService from 'services/blob.service'
import establishmentService from 'services/establishment.service'
import guestService from 'services/guest.service'
import { ROLE_SECRET_KEY } from 'utility/constants'

Aos.init({
  duration: 600,
  once: true,
})

function ReplyReview(props) {
  const {
    googleAccountId,
    googleAccessToken,
    googleLocationId,
    reviewId,
    pageSize,
    nextPageToken,
    userComment,
    isReply,
    reviewReplyComment,
    reviewResponseTime,
  } = props
  const [comment, setComment] = useState(reviewReplyComment)
  const [isReplied, setIsReplied] = useState(isReply)
  const [isEditPressed, setIsEditPressed] = useState(false)
  const [isReplayEmpty, setIsReplayEmpty] = useState(false)
  const [responseCommentPrev, setResponseCommentPrev] =
    useState(reviewReplyComment)
  const [getReviewResponseTime, setReviewResponseTime] =
    useState(reviewResponseTime)
  const sendReviewReply = async () => {
    const replayData = await authService.replyGoogleReview({
      reviewResponse: comment,
      googleAccountId,
      accessToken: googleAccessToken,
      googleLocationId,
      reviewId,
      pageSize,
      nextPageToken,
    })
    if (replayData.data.data === 'Reply sent') {
      toast.success(replayData.data.data)
    } else {
      toast.error(replayData.data.data)
    }
    setReviewResponseTime(null)
    setIsReplied(true)
    setIsEditPressed(false)
    setIsReplayEmpty(false)
    setResponseCommentPrev(comment)
  }
  const againEdit = () => {
    setIsReplied(false)
    setIsEditPressed(true)
  }
  const cancelReplay = () => {
    setIsReplayEmpty(false)
    setIsReplied(true)
    setIsEditPressed(false)
    setComment(responseCommentPrev)
  }
  return (
    <>
      {isReplied === true ? (
        <>
          {userComment && <hr />}
          <div className="review-listing-detail" style={{ display: 'flex' }}>
            <p style={{ flexGrow: 1 }}>
              <span style={{ fontWeight: 500 }}>Response from the owner :</span>{' '}
              {comment}
            </p>
            <p className="time">
              {getReviewResponseTime ? (
                <Moment format="DD MMM YYYY, hh:mm A">
                  {getReviewResponseTime}
                </Moment>
              ) : (
                <span>Just Now</span>
              )}
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={againEdit}
              style={{
                border: 0,
                backgroundColor: 'transparent',
                fontSize: '22px',
                cursor: 'pointer',
              }}
            >
              <img src={Edit} alt="Edit" />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="form-group">
            <TextField
              label="Enter a response to your review"
              fullWidth
              multiline
              rows={3}
              onChange={(params) => {
                setComment(params.target.value)
              }}
              value={comment}
              error={isReplayEmpty}
              helperText={isReplayEmpty && 'Response is required.'}
            />
          </div>

          <div className="replay-btn-outer">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (comment && comment.length > 0) {
                  sendReviewReply()
                } else {
                  setIsReplayEmpty(true)
                }
              }}
            >
              REPLY
            </Button>
            {isEditPressed && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  cancelReplay()
                }}
                className="review-cancel-button"
              >
                CANCEL
              </Button>
            )}
          </div>
        </>
      )}
    </>
  )
}

function ReviewForm(props) {
  const {
    isRespond,
    restaurantNote,
    guestRatingId,
    isReachOutExp,
    IsAdmin,
    emailAddress,
  } = props

  const [getRespond, setRespond] = useState(isRespond.toString())
  const [getRestaurantNote, setRestaurantNote] = useState(restaurantNote)
  const [getReviewColor, setReviewColor] = useState('bg-review-remove')

  const updateReview = async () => {
    const val = !!getRespond.includes('true')
    const msg = await guestService.updateReview({
      guestRatingId,
      isRespond: val,
      restaurantNote: val ? getRestaurantNote : '',
    })
    if (val) {
      setReviewColor('bg-review')
    } else {
      setReviewColor('bg-review-remove')
    }
    toast.success(msg.data.message)
  }
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setRespond(value)
  }

  return (
    <div className={getReviewColor}>
      <div
        className={`${getRespond === 'true' ? 'd-none' : 'inline-block'}`}
        style={{ float: 'right' }}
      >
        {isReachOutExp || IsAdmin ? (
          <Button
            variant="contained"
            size="small"
            onClick={(e) => {
              e.preventDefault()
              window.location.href = `mailto:${emailAddress}`
            }}
          >
            <a
              href={`mailto:${emailAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#ffffff' }}
            >
              REPLY
            </a>
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div style={{ marginBottom: '20px', position: 'inherit' }}>
        <span>Did you respond to this guest inquiry?</span>
        <ToggleButtonGroup
          color="primary"
          value={getRespond}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          style={{ marginLeft: '10px' }}
        >
          <ToggleButton value="true">Yes</ToggleButton>
          <ToggleButton value="false">No</ToggleButton>
        </ToggleButtonGroup>
        {/* <Switch
          onChange={(e) => setRespond(e.target.checked)}
          defaultChecked={getRespond}
        /> */}
      </div>

      <div className={`form-group ${getRespond === 'true' ? '' : 'd-none'}`}>
        <TextField
          label="Enter a restaurant note"
          fullWidth
          multiline
          rows={3}
          value={getRestaurantNote}
          onChange={(e) => {
            setRestaurantNote(e.target.value)
          }}
        />
      </div>
      <Button
        variant="contained"
        type="button"
        color="primary"
        title="Save"
        size="small"
        onClick={updateReview}
      >
        Save
      </Button>
    </div>
  )
}

function Reviews() {
  const [reviewData, setReviewData] = useState([])
  const [reviewGraphData, setReviewGraphData] = useState([])
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const establishmentLists = useSelector(establishmentList)
  const currentEstablishment = establishmentLists.find(
    (x) => x.establishmentId === selectedCurrentEstablishment
  )
  const containerName = process.env.REACT_APP_AZURE_PUBLIC_CONTAINER
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [],
  })
  const [totalRecords, setTotalRecords] = useState(0)
  const [averageRating, setAverageRating] = useState(0)
  const [starRating, setStarRating] = useState(0)
  const [platForm, setPlatform] = useState(0)
  const [showComments, setShowComments] = useState(0)
  const [dateFilter, setDateFilter] = useState(1)
  const [getAvgService, setAvgService] = useState(0)
  const [getAvgWaitTime, setAvgWaitTime] = useState(0)
  const [getAvgPayment, setAvgPayment] = useState(0)
  const [getAvgFood, setAvgFood] = useState(0)
  const [getAvgValue, setAvgValue] = useState(0)
  const [getAvgAtmosphere, setAvgAtmoshphere] = useState(0)
  const [getTotalGoogleReview, setTotalGoogleReview] = useState(0)
  const [getAverageGoogleReview, setAverageGoogleReview] = useState(0)
  const [isGoogleLoginEnable, setIsGoogleLoginEnable] = useState(false)
  const [googleClientId, setGoogleClientId] = useState('')
  const [isGoogleKeyObtained, setIsGoogleKeyObtained] = useState(false)
  const [googleAccessToken, setGoogleAccessToken] = useState(null)
  const [googleAccountId, setGoogleAccountId] = useState(null)
  const [googleLocations, setGoogleLocations] = useState([])
  const [displayLocationDialog, setDisplayLocationDialog] = useState(false)
  const [getGoogleReviewList, setGoogleReviewList] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [pageSize, setPageSize] = useState(50)
  const [hasContainsPrevPage, setHasContainsPrevPage] = useState(false)
  const [hasContainsNextPage, setHasContainsNextPage] = useState(false)
  const [googleLocationId, setGoogleLocationId] = useState(null)
  const [googleLocationName, setGoogleLocationName] = useState(null)
  const [googleSecretKey, setGoogleSecretKey] = useState(null)
  const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false)
  const [getDbReview, setDbReview] = useState([])
  const [getChildEst, setChildEst] = useState('0')
  const [getReviewDifference, setReviewDifference] = useState(0)
  const [token, setToken] = useState(null)
  const [storgaeName, setStorageName] = useState(null)
  const [serverReviewData, setServerReviewData] = useState([])
  const [isInternalReviewActivated, setIsInternalReviewActivated] =
    useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openConfigDialog, setOpenConfigDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
  const [openViewDialog, setOpenViewDialog] = useState(false)
  const [redirectLogoPaths, setRedirectLogoPaths] = useState('')
  const [redirectDescriptionTexts, setRedirectDescriptionTexts] = useState('')
  const [redirectSubmitButtonTexts, setRedirectSubmitButtonTexts] = useState('')
  const [redirectionTriggers, setRedirectionTriggers] = useState(false)
  const [redirectStars, setRedirectStars] = useState(1)
  const [redirectURLs, setRedirectURLs] = useState('')
  const [file, setFile] = useState<File>(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [descriptionError, setDescriptionError] = useState(false)
  const [submitButtonTextError, setSubmitButtonTextError] = useState(false)
  const [urlError, setUrlError] = useState(false)
  const [internalEditDisabled, setInternalEditDisabled] = useState(true)
  const [locationList, setLocationList] = useState([])
  const dispatch = useDispatch()
  const getGoogleLoginKeys = async () => {
    const data = await authService.getGoogleLoginKey()
    if (data.data.data.googleClientId) {
      setGoogleClientId(data.data.data.googleClientId)
      setIsGoogleKeyObtained(true)
    }
    const dataSecret = await authService.getGoogleSecretKey()
    if (dataSecret.data.data.googleSecretKey) {
      setGoogleSecretKey(dataSecret.data.data.googleSecretKey)
    }
  }

  const IsAdmin =
    Number(
      CryptoJS.AES.decrypt(
        localStorage.getItem('roleId'),
        ROLE_SECRET_KEY
      )?.toString(CryptoJS.enc.Utf8)
    ) === 1

  const IsRestaurantAdmin =
    Number(
      CryptoJS.AES.decrypt(
        localStorage.getItem('roleId'),
        ROLE_SECRET_KEY
      )?.toString(CryptoJS.enc.Utf8)
    ) === 3

  const IsLocationAdmin =
    Number(
      CryptoJS.AES.decrypt(
        localStorage.getItem('roleId'),
        ROLE_SECRET_KEY
      )?.toString(CryptoJS.enc.Utf8)
    ) === 6

  useEffect(() => {
    if (getChildEst !== '0') {
      const currentRev = getDbReview.find(
        (dbRev) => dbRev.googleLocationId === getChildEst
      )
      if (currentRev) {
        setAverageGoogleReview(currentRev.averageRating)
        setTotalGoogleReview(currentRev.totalReviews)
        setReviewDifference(currentRev.diffrenceReviews)
      }
    } else {
      setAverageGoogleReview(0)
      setTotalGoogleReview(0)
      setReviewDifference(0)
    }
  }, [getChildEst, getDbReview])
  const getEstablishmentList = async () => {
    try {
      const data = await establishmentService.getAllEstablishments({
        pageNo: 1,
        pageSize: 0,
        sortGridModels: [{ field: 'restaurantName', sort: 0 }],
        restaurantId: selectedRestaurantId,
      })

      if (selectedRestaurantId !== null && selectedRestaurantId !== 0) {
        const list = data.data.data.data.map((e) => {
          return {
            key: e.establishmentName,
            value: e.establishmentId,
          }
        })
        list.splice(0, 0, {
          key: 'All',
          value: 0,
        })
        const currentEstablishments = data.data.data.data.find(
          (d) => d.establishmentId === selectedCurrentEstablishment
        )
        setLocationList(data.data.data.data)
        dispatch(setEstablishments({ establishmentList: data.data.data.data }))
        dispatch(
          setCurrentEstablishment({
            currentEstablishment: currentEstablishments,
          })
        )
      }
    } catch (e) {
      if (localStorage.length !== 0) {
        toast.error('Failed to retrieve establishment list.')
      }
    }
  }

  useEffect(() => {
    getEstablishmentList()
    console.log(selectedRestaurantId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId])

  const getData = async (restaurantId: number, establishmentId: number) => {
    if (
      restaurantId != null &&
      establishmentId != null &&
      restaurantId > 0 &&
      establishmentId > 0
    ) {
      const respData = await guestService.getFastabReview({
        restaurantId: restaurantId === 0 ? null : restaurantId,
        establishmentId: establishmentId === 0 ? null : establishmentId,
        pageNo: dataGridOptions.pageNumber,
        pageSize: dataGridOptions.pageSize,
        starRating,
        dateFilter,
        showComments,
      })
      const allRating = respData.data.data.data
      let avgService = 0
      let avgWaitTime = 0
      let avgPayment = 0
      let avgFood = 0
      let avgValue = 0
      let avgAtmosphere = 0

      for (let ind = 0; ind < allRating.length; ind += 1) {
        if (allRating[ind].isServiceGood === true) {
          avgService =
            (avgService + allRating[ind].starRating) /
            (avgService === 0 ? 1 : 2)
        }

        if (allRating[ind].isWaitTimeGood === true) {
          avgWaitTime =
            (avgWaitTime + allRating[ind].starRating) /
            (avgWaitTime === 0 ? 1 : 2)
        }

        if (allRating[ind].isPaymentGood === true) {
          avgPayment =
            (avgPayment + allRating[ind].starRating) /
            (avgPayment === 0 ? 1 : 2)
        }

        if (allRating[ind].isFoodGood === true) {
          avgFood =
            (avgFood + allRating[ind].starRating) / (avgFood === 0 ? 1 : 2)
        }

        if (allRating[ind].isValueGood === true) {
          avgValue =
            (avgValue + allRating[ind].starRating) / (avgValue === 0 ? 1 : 2)
        }
        if (allRating[ind].isAtmosphereGood === true) {
          avgAtmosphere =
            (avgAtmosphere + allRating[ind].starRating) /
            (avgAtmosphere === 0 ? 1 : 2)
        }
      }
      setAvgService(Number(avgService.toFixed(0)))
      setAvgWaitTime(Number(avgWaitTime.toFixed(0)))
      setAvgPayment(Number(avgPayment.toFixed(0)))
      setAvgFood(Number(avgFood.toFixed(0)))
      setAvgValue(Number(avgValue.toFixed(0)))
      setAvgAtmoshphere(Number(avgAtmosphere.toFixed(0)))
      setTotalRecords(respData.data.data.totalRecords)
      setReviewData(respData.data.data.data)
      setAverageRating(respData.data.data.averageRating)
      setReviewGraphData(respData.data.data.reviewsGraphData)
      setDbReview(respData.data.data.googleReviews)
      setChildEst('0')
      setServerReviewData(respData.data.data.serverRatings)
      setIsInternalReviewActivated(respData.data.data.isInternalReview)
      // await getEstablishmentList()
      // const googleReview = await guestService.getGoogleReview({
      //   establishmentId,
      // })
      // setTotalGoogleReview(googleReview.data.data.userRatingsTotal)
      // setAverageGoogleReview(googleReview.data.data.rating)
    }
  }
  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const getNumberFromText = (text: string) => {
    switch (text) {
      case 'ONE':
        return 1
      case 'TWO':
        return 2
      case 'THREE':
        return 3
      case 'FOUR':
        return 4
      case 'FIVE':
        return 5
      default:
        return 0
    }
  }

  // const getStarHtml = (value: number) => {
  //   return [...Array(5)].map((e, i) => {
  //     if (i < Number(value)) {
  //       return (
  //         <span key={i} className="star-rating">
  //           <img src={StarFilled} alt="" />
  //         </span>
  //       )
  //     }
  //     return (
  //       <span key={i} className="star-rating">
  //         <img src={StarNormal} alt="" />
  //       </span>
  //     )
  //   })
  // }
  const GetGoogleReviews = async (gAccessToken, gLocationId, gAccountId) => {
    if (gAccessToken && googleLocationId && gAccountId) {
      const data = await authService.getGoogleReview({
        accessToken: gAccessToken,
        googleLocationId: gLocationId,
        googleAccountId: gAccountId,
        nextPageToken,
        pageSize,
      })
      setAverageGoogleReview(data.data.data.averageRating)
      setTotalGoogleReview(data.data.data.reviews.length)
      setDisplayLocationDialog(false)
      setGoogleReviewList(data.data.data.reviews)
      setNextPageToken(data.data.data.nextPageToken)
      if (data.data.data.nextPageToken) {
        setHasContainsNextPage(true)
      } else {
        setHasContainsNextPage(false)
      }
    }
  }
  const getAccessToken = async (refreshToken) => {
    const dataAccessToken = await authService.getAccessTokenByRefreshToken({
      refreshToken,
    })
    setGoogleAccessToken(dataAccessToken.data.data.accessToken)
    const data = await authService.getGoogleLocation({
      googleAccessToken: dataAccessToken.data.data.accessToken,
    })
    setGoogleAccountId(data.data.data.accountId)
    data.data.data.locations.forEach(async (item) => {
      if (item.name === localStorage.getItem('googleLocationName')) {
        setGoogleLocationId(item.name)
        setGoogleLocationName(item.title)
        setDisplayLocationDialog(false)
        if (googleLocationId === item.name) {
          await GetGoogleReviews(
            dataAccessToken.data.data.accessToken,
            item.name,
            data.data.data.accountId
          )
        }
      }
    })
    setGoogleLocations(data.data.data.locations)
  }

  useEffect(() => {
    if (!isAlreadyLoggedIn) {
      setAverageGoogleReview(0)
      setTotalGoogleReview(0)
      setGoogleReviewList([])
      setGoogleLocationName(null)
      setGoogleLocationId(null)
      setGoogleAccessToken(null)
      setGoogleAccountId(null)
      window.sessionStorage.removeItem('access_token')
      window.sessionStorage.removeItem('nama')
    }
  }, [isAlreadyLoggedIn])

  const addRefreshToken = async (refreshTokenData) => {
    const localRefreshToken = JSON.parse(refreshTokenData)
    if (
      selectedCurrentEstablishment === localRefreshToken.establishmentId &&
      localRefreshToken.refreshToken
    ) {
      await getAccessToken(localRefreshToken.refreshToken)
      setIsAlreadyLoggedIn(true)
    } else {
      setIsAlreadyLoggedIn(false)
    }
  }
  useEffect(() => {
    const refreshTokenData = localStorage.getItem('googleRefreshToken')
    if (refreshTokenData) {
      addRefreshToken(refreshTokenData)
    } else {
      setIsAlreadyLoggedIn(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrentEstablishment])
  const setLocalStorageParams = (tabIdforDetailRequest?) => {
    localStorage.setItem('fromReview', 'true')
    localStorage.setItem('tabIdforDetailRequest', tabIdforDetailRequest)
    localStorage.setItem(
      'restaurantIdForDetailRequest',
      selectedRestaurantId.toString()
    )
    localStorage.setItem(
      'establishmentIdForDetailRequest',
      selectedCurrentEstablishment.toString()
    )
  }

  const changeStarRating = (value: string) => {
    setStarRating(Number(value))
  }
  const changePlatform = (value: string) => {
    setPlatform(Number(value))
  }
  const changeDateFilter = (value: string) => {
    setDateFilter(Number(value))
  }
  const changeCommentsFilter = (value: string) => {
    setShowComments(Number(value))
  }
  const restFilter = () => {
    setStarRating(0)
    setPlatform(0)
    setDateFilter(1)
    setShowComments(0)
  }
  const Root = (props) => (
    <Legend.Root
      {...props}
      sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }}
    />
  )

  const PercentageLabel = (props) => {
    const { text } = props
    return <ValueAxis.Label {...props} text={`${text} %`} />
  }
  const ChangeToolTip = (props) => {
    const { text } = props
    return <div>{`${text} %`}</div>
  }

  const Label = (props) => (
    <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
  )

  const getReviewHeading = (
    isReachOutExp: boolean,
    fullName: string,
    emailAddress: string
  ) => {
    if (isReachOutExp || IsAdmin) {
      return (
        <>
          <Typography>{fullName}</Typography>
          <span style={{ fontWeight: 400, color: '#737373' }}>
            {emailAddress}
          </span>
        </>
      )
    }
    return <Typography>Restaurant Guest</Typography>
  }
  const getSocialLoginStatus = async () => {
    const result = await authService.getSocialLoginStatus()
    const response = result.data.data
    setIsGoogleLoginEnable(response.isGoogleEnable)
  }

  const getSasToken = async () => {
    if (!token) {
      const azureData = await blobService.getAzureDetails()
      setToken(azureData.data.data.sasToken)
      setStorageName(azureData.data.data.azureStorageAccountName)
    }
  }

  useEffect(() => {
    getSocialLoginStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getSasToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isGoogleLoginEnable) {
      getGoogleLoginKeys()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGoogleLoginEnable])

  useEffect(() => {
    getData(selectedRestaurantId, selectedCurrentEstablishment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedCurrentEstablishment,
    dataGridOptions.pageNumber,
    dataGridOptions.pageSize,
    starRating,
    dateFilter,
    showComments,
  ])
  useEffect(() => {
    if (googleClientId) {
      const loadGoogle = () => {
        gapi.client.init({
          clientId: googleClientId,
          grant_type: 'offline',
          scope:
            'https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage email',
        })
      }
      gapi.load('client:auth2', loadGoogle)
    }
  }, [googleClientId])

  const getTokenUri = async (code) => {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `code=${code}&client_id=${googleClientId}&client_secret=${googleSecretKey}&redirect_uri=${window.location.origin}&grant_type=authorization_code`,
    })
    if (response.ok) {
      const data = await response.json()
      localStorage.setItem(
        'googleRefreshToken',
        `{"establishmentId":${selectedCurrentEstablishment},"refreshToken":"${data.refresh_token}"}`
      )
    } else {
      // Handle error response
      console.error('Failed to fetch token URI')
    }
  }

  const handleGoogleLogin = async (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    getTokenUri(res.code)
    const data = await authService.getGoogleLocation({
      googleAccessToken: gapi.auth.getToken().access_token,
    })

    setGoogleAccessToken(gapi.auth.getToken().access_token)
    setGoogleAccountId(data.data.data.accountId)
    setGoogleLocations(data.data.data.locations)
    setIsAlreadyLoggedIn(true)
    if (data.data.data.locations.length === 1) {
      localStorage.setItem(
        'googleLocationName',
        data.data.data.locations[0].name
      )
      setGoogleLocationId(data.data.data.locations[0].name)
      setGoogleLocationName(data.data.data.locations[0].title)
      setDisplayLocationDialog(false)
      if (googleLocationId === data.data.data.locations[0].name) {
        await GetGoogleReviews(
          gapi.auth.getToken().access_token,
          data.data.data.locations[0].name,
          data.data.data.accountId
        )
      }
    } else {
      setDisplayLocationDialog(true)
    }
  }

  const handleGoogleLoginFailure = (res) => {
    if (res.error === 'popup_closed_by_user') {
      console.log(res)
    } else if (res.error === 'access_denied') {
      toast.error(
        'Please enable the permission which are required to the scopes'
      )
    } else if (res.error === 'idpiframe_initialization_failed') {
      toast.error('Please enable  third party cookies  in your browser')
    } else {
      toast.error('Google login failed, please try again after some time.')
    }
    console.log(res)
  }

  const onClose = () => {
    setDisplayLocationDialog(false)
    return displayLocationDialog
  }

  const handleCancelClick = () => {
    setDisplayLocationDialog(false)
  }

  const goToPreveGoogleReview = async () => {
    setHasContainsPrevPage(false)
    if (hasContainsPrevPage) {
      setNextPageToken(null)
      await GetGoogleReviews(
        googleAccessToken,
        googleLocationId,
        googleAccountId
      )
    }
  }
  const goToNextGoogleReview = async () => {
    if (nextPageToken) {
      setHasContainsPrevPage(true)
      await GetGoogleReviews(
        googleAccessToken,
        googleLocationId,
        googleAccountId
      )
    }
  }
  const reviewLogOut = () => {
    setIsAlreadyLoggedIn(false)
    setGoogleAccessToken(null)
    setGoogleLocationId(null)
    setGoogleAccountId(null)
    window.sessionStorage.removeItem('access_token')
    window.sessionStorage.removeItem('nama')
    localStorage.removeItem('googleLocationName')
    localStorage.removeItem('googleRefreshToken')
  }
  useEffect(() => {
    if (pageSize > 0) {
      GetGoogleReviews(googleAccessToken, googleLocationId, googleAccountId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  useEffect(() => {
    if (googleLocationId) {
      GetGoogleReviews(googleAccessToken, googleLocationId, googleAccountId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleLocationId])

  const starList = [
    { key: '1 Star', value: 1 },

    { key: '2 Stars', value: 2 },

    { key: '3 Stars', value: 3 },

    { key: '4 Stars', value: 4 },

    { key: '5 Star', value: 5 },
  ]

  const triggerList = [
    { key: 'Below', value: 0 },
    { key: 'Above', value: 1 },
  ]

  const handleStarSelectChange = (event) => {
    setRedirectStars(event)
  }

  const handleTriggerSelectChange = (event) => {
    if (event === 1) {
      setRedirectionTriggers(true)
    } else {
      setRedirectionTriggers(false)
    }
  }

  const updateInternalReview = async (
    estId,
    isActive,
    rerouteLogoPath,
    rerouteDescriptionText,
    rerouteSubmitButtonText,
    rerouteTrigger,
    reroutetStar,
    rerouteURL
  ) => {
    try {
      await guestService.updateInternalReview({
        establishmentId: estId,
        isInternalReview: isActive,
        redirectLogoPath: isActive === true ? rerouteLogoPath : '',
        redirectDescriptionText:
          isActive === true ? rerouteDescriptionText : '',
        redirectSubmitButtonText:
          isActive === true ? rerouteSubmitButtonText ?? 'OK' : 'OK',
        redirectionTrigger: isActive === true ? rerouteTrigger : false,
        redirectStar: isActive === true ? reroutetStar : 0,
        redirectURL: isActive === true ? rerouteURL : '',
      })
      setRedirectLogoPaths('')
      setRedirectDescriptionTexts('')
      setRedirectSubmitButtonTexts('')
      setRedirectionTriggers(false)
      setRedirectStars(1)
      setRedirectURLs('')
      await getEstablishmentList()
      toast.success('Re-Route updated successfully')
      setIsInternalReviewActivated(isActive)
    } catch {
      toast.error('Something went wrong! Please try again.')
    }
  }

  const setInternalEditStates = () => {
    if (selectedRestaurantId !== null && selectedRestaurantId !== 0) {
      const selectedCurrentEstablishments = locationList.find(
        (d) => d.establishmentId === selectedCurrentEstablishment
      )
      setRedirectDescriptionTexts(
        selectedCurrentEstablishments.redirectDescriptionText
      )
      setRedirectSubmitButtonTexts(
        selectedCurrentEstablishments.redirectSubmitButtonText
      )
      setRedirectionTriggers(selectedCurrentEstablishments.redirectionTrigger)
      setRedirectStars(selectedCurrentEstablishments.redirectStar)
      setRedirectURLs(selectedCurrentEstablishments.redirectURL)
      setRedirectLogoPaths(selectedCurrentEstablishments.redirectLogoPath)
      setFile(null)
    }
  }

  const setInterEditStateEmpty = () => {
    setRedirectDescriptionTexts('')
    setRedirectSubmitButtonTexts('')
    setRedirectionTriggers(false)
    setRedirectStars(1)
    setRedirectURLs('')
    setRedirectLogoPaths('')
    setFile(null)
  }
  const setLocalStorageParamsForServer = (serverId, serverName, date) => {
    localStorage.setItem('serverReviewId', serverId)
    localStorage.setItem('serverReviewName', serverName)
    localStorage.setItem('serverReviewDate', date)
    localStorage.setItem('restaurantId', selectedRestaurantId.toString())
    localStorage.setItem(
      'establishmentId',
      selectedCurrentEstablishment.toString()
    )
  }

  const handleReviewRedirect = async (isEdit?: boolean) => {
    let oldState = !isInternalReviewActivated
    if (isEdit) {
      oldState = true
    }
    let redirectLogo = null
    if (file) {
      const fileName = file.name
      await blobService.uploadFileToBlob(
        file,
        fileName,
        containerName,
        storgaeName || '',
        token || ''
      )
      setRedirectLogoPaths(`${containerName}/${fileName}`)
      redirectLogo = `${containerName}/${fileName}`
    } else if (redirectLogoPaths?.includes('?')) {
      const mainUrl = redirectLogoPaths.split('?')[0]
      const path = mainUrl.split(containerName)[1]
      setRedirectLogoPaths(`${containerName}${path}`)
      redirectLogo = `${containerName}/${path}`
    }
    if (
      oldState === false &&
      currentEstablishment?.redirectLogoPath !== null &&
      currentEstablishment?.redirectLogoPath !== ''
    ) {
      const urlSplit = currentEstablishment?.redirectLogoPath.split('/')
      await blobService.deleteFile(
        urlSplit[urlSplit.length - 1].split('?')[0],
        containerName,
        storgaeName,
        token
      )
      redirectLogo = null
    }
    await updateInternalReview(
      selectedCurrentEstablishment,
      oldState,
      redirectLogo === null ? redirectLogo : redirectLogo.replace('//', '/'),
      redirectDescriptionTexts,
      redirectSubmitButtonTexts,
      redirectionTriggers,
      redirectStars,
      redirectURLs
    )
  }

  const handleImagePreview = (imageFile: File) => {
    if (imageFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(imageFile)
    }
  }

  const handleFileChange = (fileData: File) => {
    setFile(fileData)
    // setName(fileData.name)
    setRedirectLogoPaths(`${containerName}/${fileData.name}`)
    handleImagePreview(fileData)
  }

  const deleteUploadedFile = (fileData: File) => {
    if (fileData === null) {
      setFile(null)
      setRedirectLogoPaths(null)
      setImagePreview(null)
    }
  }

  const handleDescriptionChange = (event) => {
    const inputValue = event.target.value
    setRedirectDescriptionTexts(inputValue)

    if (inputValue.length >= 141 || inputValue.length === 0) {
      setDescriptionError(true)
    } else {
      setDescriptionError(false)
    }
  }

  const handleSubmitTextChange = (event) => {
    const inputValue = event.target.value
    setRedirectSubmitButtonTexts(inputValue)

    if (inputValue.length >= 26) {
      setSubmitButtonTextError(true)
    } else {
      setSubmitButtonTextError(false)
    }
  }

  const handleURLChange = (event) => {
    const inputValue = event.target.value
    setRedirectURLs(inputValue)

    if (inputValue.length <= 0) {
      setUrlError(true)
    } else {
      setUrlError(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>Reviews | FasTab</title>
      </Helmet>
      <Sidebar />
      <section className="wrapper">
        <Header />
        {/* page-top start */}
        {selectedCurrentEstablishment !== 0 &&
        selectedCurrentEstablishment !== null ? (
          <section className="main-content">
            <div
              className="page-top review-heading"
              style={
                currentEstablishment && currentEstablishment.isShowGoogleReview
                  ? { paddingBottom: '5px' }
                  : {}
              }
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} md="auto">
                  <h3 className="page-title">Reviews</h3>
                </Grid>

                <Grid item xs={12} md="auto">
                  <div className="right d-column">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md="auto">
                        <div className="form-group">
                          <TextField
                            id="outlined-select-currency"
                            select
                            defaultValue={dateFilter}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={TimeIcon}
                                    className="default"
                                    alt="Restaurant Menu"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) => {
                              changeDateFilter(event.target.value)
                            }}
                            value={dateFilter}
                          >
                            {/* <MenuItem key="0" value="0">
                              All Time
                            </MenuItem> */}
                            <MenuItem key="1" value="1">
                              Today
                            </MenuItem>
                            <MenuItem key="2" value="2">
                              Yesterday
                            </MenuItem>
                            {/* <MenuItem key="3" value="3">
                              Most Recent
                            </MenuItem> */}
                            <MenuItem key="4" value="4">
                              This Week
                            </MenuItem>
                            <MenuItem key="5" value="5">
                              Last Week
                            </MenuItem>
                            <MenuItem key="6" value="6">
                              Past Two Weeks
                            </MenuItem>
                            <MenuItem key="7" value="7">
                              This Month
                            </MenuItem>
                            <MenuItem key="8" value="8">
                              Last Month
                            </MenuItem>
                            <MenuItem key="9" value="9">
                              Last Year
                            </MenuItem>
                          </TextField>
                        </div>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <div className="form-group">
                          <TextField
                            id="outlined-select-currency"
                            select
                            defaultValue={platForm}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={AllPlatformIcon}
                                    className="default"
                                    alt="Restaurant Menu"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) => {
                              changePlatform(event.target.value)
                            }}
                            value={platForm}
                          >
                            <MenuItem key="0" value="0">
                              All Platforms
                            </MenuItem>
                            <MenuItem key="1" value="1">
                              FasTab
                            </MenuItem>
                            <MenuItem key="2" value="2">
                              Google
                            </MenuItem>
                          </TextField>
                        </div>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <div className="form-group">
                          <TextField
                            id="outlined-select-currency"
                            select
                            defaultValue={showComments}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={Comments}
                                    className="default"
                                    alt="Restaurant Menu"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) => {
                              changeCommentsFilter(event.target.value)
                            }}
                            value={showComments}
                          >
                            <MenuItem key="0" value="0">
                              All Reviews
                            </MenuItem>
                            <MenuItem key="1" value="1">
                              Reviews with Comments
                            </MenuItem>
                            <MenuItem key="2" value="2">
                              Contactable Reviews
                            </MenuItem>
                          </TextField>
                        </div>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <div className="form-group">
                          <TextField
                            id="outlined-select-currency"
                            select
                            defaultValue={starRating}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={StarIcon}
                                    className="default"
                                    alt="Restaurant Menu"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) => {
                              changeStarRating(event.target.value)
                            }}
                            value={starRating}
                          >
                            <MenuItem key="0" value="0">
                              All
                            </MenuItem>
                            <MenuItem key="1" value="1">
                              One
                            </MenuItem>
                            <MenuItem key="2" value="2">
                              Two
                            </MenuItem>
                            <MenuItem key="3" value="3">
                              Three
                            </MenuItem>
                            <MenuItem key="4" value="4">
                              Four
                            </MenuItem>
                            <MenuItem key="5" value="5">
                              Five
                            </MenuItem>
                          </TextField>
                        </div>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={restFilter}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                    <span className="filter-error">
                      Note : Filters are only applicable to FasTab review.
                    </span>
                  </div>
                  {(IsAdmin || IsRestaurantAdmin || IsLocationAdmin) &&
                    currentEstablishment &&
                    currentEstablishment.isShowGoogleReview && (
                      <div className="internal-review-div">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isInternalReviewActivated}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setInterEditStateEmpty()
                                  setOpenConfigDialog(true)
                                } else {
                                  setInternalEditDisabled(true)
                                  setOpenConfirmDialog(true)
                                }
                              }}
                              name="Activate"
                            />
                          }
                          label="Activate External Link Button"
                        />
                        {isInternalReviewActivated && (
                          <div style={{ marginTop: '7px', display: 'flex' }}>
                            <Button
                              className="icon-btn"
                              title="View"
                              variant="text"
                              color="inherit"
                              onClick={() => {
                                setOpenViewDialog(true)
                                setInternalEditDisabled(true)
                                setInternalEditStates()
                              }}
                              style={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: '#f3f5f8',
                                marginRight: '5px',
                                border: '0px',
                              }}
                            >
                              <img src={View} alt="View" />
                            </Button>
                            <Button
                              className="icon-btn"
                              title="Edit"
                              variant="text"
                              color="inherit"
                              onClick={() => {
                                setOpenViewDialog(true)
                                setInternalEditDisabled(false)
                                setInternalEditStates()
                              }}
                              style={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: '#f3f5f8',
                                marginRight: '5px',
                                border: '0px',
                              }}
                            >
                              <img src={Edit} alt="Edit" />
                            </Button>
                          </div>
                        )}

                        <div style={{ marginTop: '7px' }}>
                          <ToolTips
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Please note, by turning on the internal review button you will be disabling your 5 star FasTab reviews from being directed to Google. 5 star reviews will now be redirected to your internal review system. To turn Google review redirection back on, simply turn off the internal review button."
                            style={{ marginLeft: '0px', marginTop: '0px' }}
                          >
                            <InfoOutlinedIcon />
                          </ToolTips>
                        </div>
                      </div>
                    )}
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="card mb-30 review-via-block" data-aos="fade-up">
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <p>
                        All time rating via{' '}
                        <Link to="/reviews" title="Fastab">
                          <span style={{ color: '#59B3F6' }}>FasTab</span>
                        </Link>
                      </p>
                      <Typography variant="h4">
                        {averageRating.toFixed(1)}/5.0
                      </Typography>
                      <p className="mb-0">
                        {totalRecords} <span>&nbsp;Reviews</span>
                      </p>
                    </Grid>
                    <Grid item style={{ display: 'grid' }}>
                      <img src={FastabLogoIcon} alt="" />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="card mb-30 review-via-block" data-aos="fade-up">
                  <Grid container justifyContent="space-between">
                    <Grid item md={12} xs={12}>
                      <div className="google-flex">
                        <p>
                          All time rating via{' '}
                          <Link to="/reviews" title="Google">
                            Google
                          </Link>
                        </p>
                        <div className="google-dropdown">
                          <TextField
                            id="outlined-select-currency"
                            select
                            onChange={(event) => {
                              setChildEst(event.target.value)
                            }}
                            value={getChildEst}
                            style={{ marginRight: '-8px' }}
                          >
                            <MenuItem key="0" value="0">
                              Select Google Location
                            </MenuItem>
                            {getDbReview.map((dbval) => {
                              return (
                                <MenuItem
                                  key={dbval.googleLocationName}
                                  value={dbval.googleLocationId}
                                >
                                  {dbval.googleLocationName}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </div>
                      </div>
                    </Grid>
                    <Grid item>
                      {/* <Typography variant="h5">
                        <span
                          style={{
                            color: '#3d8af7',
                          }}
                        >
                          Coming Soon... {getTotalGoogleReview}{' '}
                          {getAverageGoogleReview}
                        </span>
                      </Typography> */}

                      <Typography variant="h4">
                        {getAverageGoogleReview.toFixed(1)}/5.0
                      </Typography>
                      <p className="mb-0" style={{ display: 'flex' }}>
                        {getTotalGoogleReview} &nbsp;<span>Reviews</span>
                        {getReviewDifference > 0 && (
                          <>
                            <TrendingUpIcon
                              style={{
                                color: 'green',
                                marginLeft: '4px',
                                marginRight: '4px',
                                marginBottom: '-15px',
                              }}
                            />
                            <span>{getReviewDifference}</span>
                          </>
                        )}
                      </p>
                    </Grid>
                    {/* <Grid item>
                      <img src={GoogleLogo} alt="" />
                    </Grid> */}
                    <Grid item>
                      {!isAlreadyLoggedIn ? (
                        isGoogleLoginEnable &&
                        isGoogleKeyObtained && (
                          <GoogleLogin
                            clientId={googleClientId}
                            buttonText="Login"
                            onSuccess={handleGoogleLogin}
                            onFailure={handleGoogleLoginFailure}
                            cookiePolicy="single_host_origin"
                            prompt="consent"
                            accessType="offline"
                            responseType="code"
                            render={(renderProps) => (
                              <Button
                                color="secondary"
                                variant="outlined"
                                title="Get Google Reviews"
                                onClick={renderProps.onClick}
                                className="google-review-button"
                              >
                                <img src={GoogleLogo} alt="Google" />
                                <span>Get Google Reviews</span>
                              </Button>
                            )}
                          />
                        )
                      ) : (
                        <>
                          <Button
                            color="secondary"
                            variant="outlined"
                            title="Logout"
                            onClick={reviewLogOut}
                            className="google-review-button"
                          >
                            <img src={GoogleLogo} alt="Google" />
                            <span>Logout</span>
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div style={{ marginBottom: 24 }} className="card">
              <Paper>
                <Chart data={reviewGraphData} rotated>
                  <ArgumentAxis />
                  <ValueScale
                    name="percentageVal"
                    modifyDomain={() => [0, 100]}
                  />
                  <ValueAxis
                    labelComponent={PercentageLabel}
                    scaleName="percentageVal"
                  />

                  <BarSeries
                    name="FasTab"
                    key="fastabRating"
                    valueField="fastabRating"
                    argumentField="dateRange"
                    color="#3498db"
                    scaleName="percentageVal"
                  />
                  {/* <BarSeries
                    name="Google"
                    valueField="googleRating"
                    argumentField="dateRange"
                    color="#ea4335"
                  /> */}
                  <Animation />
                  <Legend
                    position="bottom"
                    rootComponent={Root}
                    labelComponent={Label}
                  />
                  <Title text="Reviews and Ratings Over Time" />
                  <EventTracker />
                  <Tooltip contentComponent={ChangeToolTip} />
                  <Stack />
                </Chart>
              </Paper>
            </div>
            <div className="card" style={{ marginBottom: 24 }}>
              <Typography variant="h5">
                <span style={{ color: '#737373', fontWeight: 700 }}>
                  Review Summary
                </span>
              </Typography>
              <div style={{ marginTop: 15, marginLeft: 15 }} className="d-flex">
                <div style={{ display: 'flex' }}>
                  <span className="review-summary">Service :</span>
                  <Rating
                    sx={{ gap: 2, color: '#f48330' }}
                    name="read-only"
                    value={getAvgService}
                    precision={0.1}
                    size="medium"
                    readOnly
                  />
                  {/* {getStarHtml(getAvgService)} */}
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="review-summary">Wait Time : </span>
                  <Rating
                    sx={{ gap: 2, color: '#f48330' }}
                    name="read-only"
                    value={getAvgWaitTime}
                    precision={0.1}
                    size="medium"
                    readOnly
                  />
                  {/* {getStarHtml(getAvgWaitTime)} */}
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="review-summary">Payment : </span>
                  <Rating
                    sx={{ gap: 2, color: '#f48330' }}
                    name="read-only"
                    value={getAvgPayment}
                    precision={0.1}
                    size="medium"
                    readOnly
                  />
                  {/* {getStarHtml(getAvgPayment)} */}
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="review-summary">Food : </span>
                  <Rating
                    sx={{ gap: 2, color: '#f48330' }}
                    name="read-only"
                    value={getAvgFood}
                    precision={0.1}
                    size="medium"
                    readOnly
                  />
                  {/* {getStarHtml(getAvgFood)} */}
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="review-summary">Value :</span>{' '}
                  <Rating
                    sx={{ gap: 2, color: '#f48330' }}
                    name="read-only"
                    value={getAvgValue}
                    precision={0.1}
                    size="medium"
                    readOnly
                  />
                  {/* {getStarHtml(getAvgValue)} */}
                </div>
                <div style={{ display: 'flex' }}>
                  <span className="review-summary">Atmosphere :</span>{' '}
                  <Rating
                    sx={{ gap: 2, color: '#f48330' }}
                    name="read-only"
                    value={getAvgAtmosphere}
                    precision={0.1}
                    size="medium"
                    readOnly
                  />
                  {/* {getStarHtml(getAvgAtmosphere)} */}
                </div>
              </div>
            </div>
            <div className="card" style={{ marginBottom: 24 }}>
              <Typography variant="h5">
                <span style={{ color: '#737373', fontWeight: 700 }}>
                  Server Review Summary
                </span>
              </Typography>
              <div style={{ marginTop: 15, marginLeft: 15 }} className="d-flex">
                {serverReviewData.length === 0 ? (
                  <Grid item xs={12} md="auto">
                    <div className="center">
                      <span>No Data</span>
                    </div>
                  </Grid>
                ) : (
                  <>
                    {serverReviewData?.map((data) => (
                      <>
                        {data?.serverName && (
                          <ToolTips
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            style={{ marginLeft: '0px', marginTop: '0px' }}
                            title={
                              <>
                                <p>{data?.serverName}</p>
                                <p>Average Reviews: {data?.avgRatings}/5</p>
                                <p>Total Reviews: {data?.totalReviews}</p>
                              </>
                            }
                            followCursor
                          >
                            <div style={{ display: 'flex' }}>
                              <span className="server-review">
                                <Link
                                  to="/tabs"
                                  onClick={() =>
                                    setLocalStorageParamsForServer(
                                      data?.serverId,
                                      data?.serverName,
                                      dateFilter
                                    )
                                  }
                                  style={{
                                    display: 'block',
                                  }}
                                  target="_blank"
                                >
                                  <span>{data?.serverName} :</span>
                                </Link>
                              </span>
                              <Link
                                to="/tabs"
                                onClick={() =>
                                  setLocalStorageParamsForServer(
                                    data?.serverId,
                                    data?.serverName,
                                    dateFilter
                                  )
                                }
                                style={{
                                  display: 'block',
                                }}
                                target="_blank"
                              >
                                <Rating
                                  sx={{ gap: 2, color: '#f48330' }}
                                  name="read-only"
                                  value={data?.avgRatings}
                                  precision={0.1}
                                  size="medium"
                                  readOnly
                                />
                              </Link>
                            </div>
                          </ToolTips>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
            {(platForm === 1 || platForm === 0) && (
              <div className="card mb-30 all-review-block" data-aos="fade-up">
                <Typography variant="h5">FasTab Reviews</Typography>
                <ul className="review-listing">
                  {totalRecords === 0 ? (
                    <Grid item xs={12} md="auto">
                      <div className="center">
                        <span>No Data</span>
                      </div>
                    </Grid>
                  ) : (
                    reviewData.map((data) => (
                      <li
                        style={
                          data.isRespond ? { backgroundColor: '#f1f6fb' } : {}
                        }
                      >
                        <div className="review-listing-heading">
                          <div className="review-listing-heading-logo">
                            <img src={FastabLogoIcon} alt="" />
                          </div>
                          <div className="review-listing-heading-name">
                            {getReviewHeading(
                              data.isReachOutExp,
                              data.fullName,
                              data.emailAddress
                            )}
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <Rating
                                sx={{ gap: 2, color: '#f48330' }}
                                name="read-only"
                                value={data.starRating}
                                precision={0.1}
                                size="small"
                                readOnly
                              />
                              {/* {getStarHtml(data.starRating)} */}
                              <span
                                style={{
                                  fontSize: 'small',
                                  paddingLeft: '5px',
                                }}
                              >
                                {data.starRating}/5
                              </span>
                            </div>
                          </div>
                          <span className="time">
                            <Moment format="DD MMM YYYY, hh:mm A">
                              {data.createdDate}
                            </Moment>
                          </span>
                        </div>
                        <div className="review-listing-detail">
                          <p>
                            {data.additionalFeedback}
                            {/* <Link to="/reviews" title="Read More">
                      &nbsp; Read More
                    </Link> */}
                          </p>
                          <Link
                            to="/tab-details"
                            onClick={() => setLocalStorageParams(data.tabsId)}
                            target="_blank"
                            className=""
                          >
                            <span className="go-to-tabs">
                              Go To Tabs
                              <img src={ArrowRight} alt="" />
                            </span>
                          </Link>
                          {data.isReachOutExp || IsAdmin ? (
                            <ReviewForm
                              isRespond={data.isRespond}
                              restaurantNote={data.restaurantNote}
                              guestRatingId={data.guestRatingid}
                              isReachOutExp={data.isReachOutExp}
                              IsAdmin={IsAdmin}
                              emailAddress={data.emailAddress}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* <div className="form-group">
                  <TextField
                    label="Enter a description"
                    fullWidth
                    multiline
                    rows={3}
                  />
                </div>

                <div className="replay-btn-outer">
                  <Button variant="contained" size="small">
                    REPLY
                  </Button>
                </div> */}
                      </li>
                    ))
                  )}
                </ul>
                <Pagination
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  noOfRecords={totalRecords}
                />
              </div>
            )}
            {(platForm === 2 || platForm === 0) && (
              <div className="card mb-30 all-review-block" data-aos="fade-up">
                <Typography variant="h5">
                  Google Reviews{' '}
                  {googleLocationName && `- ${googleLocationName}`}
                </Typography>
                <ul className="review-listing">
                  {getGoogleReviewList.length === 0 ? (
                    <Grid item xs={12} md="auto">
                      <div className="center">
                        <span>No Data</span>
                      </div>
                    </Grid>
                  ) : (
                    getGoogleReviewList.map((data) => (
                      <li
                        style={
                          data.isRespond ? { backgroundColor: '#f1f6fb' } : {}
                        }
                      >
                        <div className="review-listing-heading">
                          <div className="review-listing-heading-logo">
                            <img src={data.reviewer.profilePhotoUrl} alt="" />
                          </div>
                          <div className="review-listing-heading-name">
                            {getReviewHeading(
                              false,
                              data.reviewer.displayName,
                              ''
                            )}
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <Rating
                                sx={{ gap: 2, color: '#f48330' }}
                                name="read-only"
                                value={getNumberFromText(data.starRating)}
                                precision={0.1}
                                size="small"
                                readOnly
                              />
                              {/* {getStarHtml(getNumberFromText(data.starRating))} */}
                              <span
                                style={{
                                  fontSize: 'small',
                                  paddingLeft: '5px',
                                }}
                              >
                                {getNumberFromText(data.starRating).toFixed(1)}
                                /5.0
                              </span>
                            </div>
                          </div>
                          <span className="time">
                            <Moment format="DD MMM YYYY, hh:mm A">
                              {data.createTime}
                            </Moment>
                          </span>
                        </div>
                        <div className="review-listing-detail">
                          <p>{data.comment}</p>
                        </div>
                        <>
                          <ReplyReview
                            googleAccountId={googleAccountId}
                            googleAccessToken={googleAccessToken}
                            googleLocationId={googleLocationId}
                            reviewId={data.reviewId}
                            pageSize={pageSize}
                            nextPageToken={nextPageToken}
                            userComment={data.comment}
                            isReply={!!data.reviewReply}
                            reviewReplyComment={
                              data.reviewReply ? data.reviewReply.comment : ''
                            }
                            reviewResponseTime={
                              data.reviewReply
                                ? data.reviewReply.updateTime
                                : null
                            }
                          />
                        </>
                      </li>
                    ))
                  )}
                </ul>
                <div className="pagination-wrapper">
                  <div className="total-pages">
                    <span>Show</span>
                    <div className="form-group">
                      <Select
                        items={[
                          {
                            key: 10,
                            value: 10,
                          },
                          {
                            key: 20,
                            value: 20,
                          },
                          {
                            key: 50,
                            value: 50,
                          },
                        ]}
                        formikValue={pageSize}
                        handleSelectValue={async (item: number) => {
                          setPageSize(Number(item))
                        }}
                      />
                    </div>
                    <span>Entries</span>
                  </div>
                  <div>
                    <ul
                      className="MuiPagination-ul"
                      style={{ display: 'flex', columnGap: '20px' }}
                    >
                      <li>
                        <button
                          type="button"
                          onClick={goToPreveGoogleReview}
                          style={{
                            border: 0,
                            backgroundColor: 'transparent',
                            fontSize: '22px',
                          }}
                          className={
                            hasContainsPrevPage ? 'pointer' : 'disable-color'
                          }
                        >
                          <img src={IcPrev} alt="prev" />
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={goToNextGoogleReview}
                          style={{
                            border: 0,
                            backgroundColor: 'transparent',
                            fontSize: '22px',
                          }}
                          className={
                            hasContainsNextPage ? 'pointer' : 'disable-color'
                          }
                        >
                          <img src={IcNext} alt="next" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {displayLocationDialog && (
              <Dialog
                className="primary-dialog"
                onClose={onClose}
                maxWidth="xs"
                fullWidth
                open={displayLocationDialog}
              >
                <DialogTitle>
                  <h6>Which location do you want to see Google reviews for</h6>
                  <Button
                    variant="text"
                    title="Close"
                    onClick={handleCancelClick}
                    color="inherit"
                  >
                    <img src={Close} alt="Close" />
                  </Button>
                </DialogTitle>
                <DialogContent className="pb-0">
                  <div className="split-options">
                    {googleLocations.map((item) => {
                      return (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            if (googleLocationId !== item.name) {
                              setGoogleLocationId(item.name)
                              setGoogleLocationName(item.title)
                              localStorage.setItem(
                                'googleLocationName',
                                item.name
                              )
                            } else {
                              GetGoogleReviews(
                                googleAccessToken,
                                googleLocationId,
                                googleAccountId
                              )
                            }
                          }}
                          title={item.title}
                        >
                          <span>{item.title}</span>
                        </Button>
                      )
                    })}
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </section>
        ) : (
          <section className="main-content no-data">
            <p>Please Select Restaurant and Location to view Reviews.</p>
          </section>
        )}
        <></>
      </section>
      {openConfigDialog && (
        <Dialog
          className="primary-dialog alert-dialog"
          maxWidth="xl"
          fullWidth
          open={openConfigDialog}
        >
          <DialogTitle>
            <Typography variant="h6">Re-Route Page Configuration</Typography>
            <Button
              variant="text"
              title="Close"
              onClick={() => {
                setOpenConfigDialog(false)
                setImagePreview(null)
              }}
              className="icon-btn rounded ml-auto"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent className="pb-0">
            {/* content */}
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '20px' }}>
                <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  <Grid item lg={6} xs={12}>
                    <div className="file-upload-wrapper">
                      <h6>Logo</h6>
                      <small>
                        Files Supported : JPG,JPEG or PNG, Maximum File size : 1
                        mb
                      </small>
                      <FileUpload
                        fileChange={handleFileChange}
                        deleteUploadedFile={deleteUploadedFile}
                        logoUpload
                        filePath={file == null ? redirectLogoPaths : null}
                        fileTypes={['JPG', 'PNG', 'JPEG']}
                        maxSize={1}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div style={{ marginTop: '20px' }}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={2}
                        type="text"
                        label="Title Text"
                        name="titleText"
                        value={redirectDescriptionTexts}
                        onChange={handleDescriptionChange}
                        error={descriptionError}
                        helperText={
                          descriptionError ? (
                            <small style={{ fontSize: 'small' }}>
                              Description can&apos;t be empty or have more than
                              140 characters
                            </small>
                          ) : (
                            ''
                          )
                        }
                        inputProps={{ maxLength: 141, minLength: 1 }}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div style={{ marginTop: '20px' }}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={1}
                        type="text"
                        label="Submit Button Text"
                        name="submitButtonText"
                        value={redirectSubmitButtonTexts}
                        onChange={handleSubmitTextChange}
                        error={submitButtonTextError}
                        helperText={
                          submitButtonTextError ? (
                            <small style={{ fontSize: 'small' }}>
                              Submit button can&apos;t have more than 25
                              characters
                            </small>
                          ) : (
                            ''
                          )
                        }
                        inputProps={{ maxLength: 26, minLength: 0 }}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Grid>
                </div>
              </div>
              <div style={{ borderLeft: '1px solid lightgrey' }}>
                <div style={{ margin: '20px' }}>
                  <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Grid item lg={6} xs={12}>
                      <h5
                        style={{
                          marginBottom: '20px',
                          textAlign: 'center',
                        }}
                      >
                        When would you like to trigger this redirection?
                      </h5>
                      <div className="filter">
                        <div className="form-group">
                          <Select
                            label="Above/Below"
                            formikValue={redirectionTriggers ? 1 : 0}
                            items={triggerList}
                            handleSelectValue={handleTriggerSelectChange}
                          />
                        </div>
                      </div>
                      <div className="filter">
                        <div className="form-group">
                          <Select
                            label="Select Stars"
                            formikValue={
                              redirectStars === 0 ? 1 : redirectStars
                            }
                            items={starList}
                            handleSelectValue={handleStarSelectChange}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={1}
                        type="text"
                        label="Re-Route URL"
                        name="urlLink"
                        value={redirectURLs}
                        onChange={handleURLChange}
                        error={urlError}
                        helperText={
                          urlError ? (
                            <small style={{ fontSize: 'small' }}>
                              Re-Route URL can&apos;t be empty
                            </small>
                          ) : (
                            ''
                          )
                        }
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title="Save"
                onClick={() => {
                  if (
                    !descriptionError &&
                    !submitButtonTextError &&
                    !urlError
                  ) {
                    if (
                      redirectDescriptionTexts !== '' &&
                      redirectURLs !== ''
                    ) {
                      setOpenConfirmDialog(true)
                    } else {
                      setDescriptionError(true)
                      setUrlError(true)
                    }
                  }
                }}
              >
                Save
              </Button>
              <Button
                title="Cancel"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenConfigDialog(false)
                  setImagePreview(null)
                }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (
                    !descriptionError &&
                    !submitButtonTextError &&
                    !urlError
                  ) {
                    if (
                      redirectDescriptionTexts !== '' &&
                      redirectURLs !== ''
                    ) {
                      setOpenPreviewDialog(true)
                    } else {
                      setDescriptionError(true)
                      setUrlError(true)
                    }
                  }
                }}
              >
                <span>Preview</span>
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {openConfirmDialog && (
        <Dialog
          className="primary-dialog alert-dialog"
          maxWidth="xs"
          fullWidth
          open={openConfirmDialog}
        >
          <DialogTitle>
            <Button
              variant="text"
              title="Close"
              onClick={() => {
                setOpenConfirmDialog(false)
              }}
              className="icon-btn rounded ml-auto"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent className="pb-0">
            <p>
              Are you sure want to update the redirect link on guest review
              page?
            </p>
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title="Yes"
                onClick={() => {
                  setOpenConfirmDialog(false)
                  setOpenConfigDialog(false)
                  handleReviewRedirect(!internalEditDisabled)
                }}
              >
                Yes
              </Button>
              <Button
                title="No"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenConfirmDialog(false)
                }}
              >
                <span>No</span>
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {openPreviewDialog && (
        <Dialog
          className="primary-dialog alert-dialog"
          maxWidth="xs"
          fullWidth
          open={openPreviewDialog}
        >
          <DialogTitle>
            <Button
              variant="text"
              title="Close"
              onClick={() => {
                setOpenPreviewDialog(false)
              }}
              className="icon-btn rounded ml-auto"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent className="pb-0">
            <div style={{ textAlign: 'center', display: 'contents' }}>
              {(imagePreview != null ||
                (redirectLogoPaths !== '' && redirectLogoPaths != null)) && (
                <img
                  src={imagePreview ?? redirectLogoPaths}
                  alt="ImagePreview"
                  style={{
                    maxHeight: '100px',
                    maxWidth: '100%',
                    margin: '20px',
                    justifyContent: 'center',
                  }}
                />
              )}
              <p style={{ marginTop: '0' }}>{redirectDescriptionTexts}</p>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              <Button
                title="OK"
                variant="contained"
                color="primary"
                onClick={() => {
                  const sanitizedURL = redirectURLs.replace(
                    /^(https?:\/\/)?(www\.)?/,
                    ''
                  )
                  const url = new URL(`http://${sanitizedURL}`)
                  window.open(url.href, '_blank', 'noreferrer')
                }}
              >
                <span>
                  {redirectSubmitButtonTexts !== ''
                    ? redirectSubmitButtonTexts
                    : 'OK'}
                </span>
              </Button>
              <Button
                title="Cancel"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenPreviewDialog(false)
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {openViewDialog && (
        <Dialog
          className="primary-dialog alert-dialog"
          maxWidth="xl"
          fullWidth
          open={openViewDialog}
        >
          <DialogTitle>
            <h5 className="bold">
              {internalEditDisabled
                ? 'Re-Route Details'
                : 'Edit Re-Route Details'}
            </h5>
            <Button
              variant="text"
              title="Close"
              onClick={() => {
                setOpenViewDialog(false)
                setImagePreview(null)
              }}
              className="icon-btn rounded"
              color="inherit"
            >
              <img src={Close} alt="Close" />
            </Button>
          </DialogTitle>
          <DialogContent className="pb-0">
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '20px' }}>
                <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  {!internalEditDisabled ? (
                    <Grid item lg={6} xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FileUpload
                          fileChange={handleFileChange}
                          deleteUploadedFile={deleteUploadedFile}
                          logoUpload
                          filePath={file == null ? redirectLogoPaths : null}
                          fileTypes={['JPG', 'PNG', 'JPEG']}
                          maxSize={1}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Grid item lg={6} xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {(currentEstablishment?.redirectLogoPath === null ||
                          currentEstablishment?.redirectLogoPath === '') && (
                          <span
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              marginTop: '-34px',
                              fontSize: '20px',
                            }}
                          >
                            No Logo
                          </span>
                        )}{' '}
                        {currentEstablishment?.redirectLogoPath !== null &&
                          currentEstablishment?.redirectLogoPath !== '' && (
                            <img
                              src={currentEstablishment?.redirectLogoPath}
                              alt="RestaurantLogo"
                              style={{
                                maxHeight: '100px',
                                maxWidth: '100%',
                                margin: 'auto',
                                justifyContent: 'center',
                              }}
                            />
                          )}
                      </div>
                    </Grid>
                  )}
                  <Grid item lg={6} xs={12}>
                    <div style={{ marginTop: '20px' }}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={2}
                        type="text"
                        label="Title Text"
                        onChange={(event) => {
                          handleDescriptionChange(event)
                        }}
                        value={redirectDescriptionTexts}
                        style={{ width: '100%' }}
                        disabled={internalEditDisabled}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div style={{ marginTop: '20px' }}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={1}
                        type="text"
                        label="Submit Button Text"
                        onChange={handleSubmitTextChange}
                        value={redirectSubmitButtonTexts}
                        style={{ width: '100%' }}
                        disabled={internalEditDisabled}
                      />
                    </div>
                  </Grid>
                </div>
              </div>
              <div style={{ borderLeft: '1px solid lightgrey' }}>
                <div style={{ margin: '20px' }}>
                  <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Grid item lg={6} xs={12}>
                      <h5
                        style={{
                          marginBottom: '20px',
                          textAlign: 'center',
                        }}
                      >
                        When would you like to trigger this redirection?
                      </h5>
                      <div className="filter">
                        <div className="form-group">
                          <Select
                            label="Above/Below"
                            formikValue={redirectionTriggers ? 1 : 0}
                            handleSelectValue={handleTriggerSelectChange}
                            items={triggerList}
                            disabled={internalEditDisabled}
                          />
                        </div>
                      </div>
                      <div className="filter">
                        <div className="form-group">
                          <Select
                            label="Select Stars"
                            formikValue={
                              redirectStars === 0 ? 1 : redirectStars
                            }
                            handleSelectValue={handleStarSelectChange}
                            items={starList}
                            disabled={internalEditDisabled}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={1}
                        type="text"
                        label="Re-Route URL"
                        value={redirectURLs}
                        onChange={handleURLChange}
                        style={{ width: '100%' }}
                        disabled={internalEditDisabled}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="btn-list">
              {!internalEditDisabled ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    title="Save"
                    onClick={() => {
                      if (
                        !descriptionError &&
                        !submitButtonTextError &&
                        !urlError
                      ) {
                        if (
                          redirectDescriptionTexts !== '' &&
                          redirectURLs !== ''
                        ) {
                          setOpenConfirmDialog(true)
                          setOpenViewDialog(false)
                        } else {
                          setDescriptionError(true)
                          setUrlError(true)
                        }
                      }
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    title="Cancel"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenViewDialog(false)
                      setImagePreview(null)
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      if (
                        !descriptionError &&
                        !submitButtonTextError &&
                        !urlError
                      ) {
                        if (
                          redirectDescriptionTexts !== '' &&
                          redirectURLs !== ''
                        ) {
                          setOpenPreviewDialog(true)
                        } else {
                          setDescriptionError(true)
                          setUrlError(true)
                        }
                      }
                    }}
                  >
                    <span>Preview</span>
                  </Button>
                </>
              ) : (
                <Button
                  title="Cancel"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenViewDialog(false)
                  }}
                >
                  <span>Cancel</span>
                </Button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default Reviews
